import { PageHeader } from '@mosey/components/page-header/PageHeader';
import FakeNav from './components/FakeNav';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import { Button } from '@mosey/components/buttons/Button';
import { PlusIcon } from '@heroicons/react/outline';
import { getStateSealImage } from '../../../utils/seals';
import { USStateAbbrev, USStateName } from '@mosey/utils/constants/us-states';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { SelectField } from '../../../components';
import { useState } from 'react';
import { fakeLoader } from './CredentialCollection';
import { markAccountConnectionStarted } from './utils';
import { CopyButton } from '@mosey/components/buttons/CopyButton';
import { APP_BASE_URL } from '../../../settings/config';
import { CheckBoxField } from '@mosey/components/forms/CheckBoxField';

const LEGAL_ENTITIES = [
  'Acme Inc',
  'Elsewhere Cafe, Inc.',
  'Cup of Joe LLC',
].map((name) => ({
  name,
  value: name,
}));

const ACCOUNTS = [
  'Income Tax Withholding',
  'Unemployment Insurance',
  'Paid Family Medical Leave',
];

const REGIONS = Object.values(USStateName).map((name) => ({
  name,
  value: name,
}));

const AccountInput = () => {
  const { region, legal_entity } = useWatch();

  return (
    <div className="flex flex-col items-start justify-center gap-4 self-stretch border border-[#edf0f1] bg-[#f7f9f9] p-4">
      <div className="inline-flex w-[655px] items-center justify-start gap-6">
        <div className="text-base font-bold leading-normal text-[#292a2d]">
          Accounts to add:
        </div>
      </div>
      {region && legal_entity ? (
        <div className="flex w-[625px] flex-col items-stretch">
          {ACCOUNTS.map((account) => (
            <CheckBoxField
              key={account}
              name={account}
              label={account}
              className="mb-3"
              labelClassName="text-base font-normal"
            />
          ))}
        </div>
      ) : (
        <p className="text-gray-500">
          Select a customer and region to see available accounts.
        </p>
      )}
    </div>
  );
};

const GenerateLink = () => {
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState('');
  const accounts = [
    useWatch({ name: ACCOUNTS[0] }) && ACCOUNTS[0],
    useWatch({ name: ACCOUNTS[1] }) && ACCOUNTS[1],
    useWatch({ name: ACCOUNTS[2] }) && ACCOUNTS[2],
  ].filter(Boolean);

  const loadLink = () => {
    setLoading(true);
    fakeLoader(1000).then(() => {
      setLink(`${APP_BASE_URL}/tax-accounts/connect?token=36e631f62b712b5b`);
      markAccountConnectionStarted();
      setLoading(false);
    });
  };
  return accounts.length > 0 ? (
    <div className="flex flex-col items-start justify-center gap-4 self-stretch border border-[#edf0f1] bg-[#f7f9f9] p-4">
      <div className="inline-flex items-center justify-start gap-4">
        <div className="flex h-[70px] min-w-[450px] items-center justify-between gap-4 rounded-[5px] border border-[#787986] px-4 py-[11px]">
          <div className="flex flex-1 items-center gap-3.5">
            <img
              src={getStateSealImage(USStateAbbrev.California)}
              className="size-12"
              alt="State seal"
            />
            <div className="inline-flex w-[153px] flex-col items-start justify-start flex-1">
              <div className="self-stretch text-base font-medium leading-tight text-[#203435]">
                California
              </div>
              <div className="text-sm leading-tight text-[#717171]">
                {accounts.length === 1
                  ? accounts[0]
                  : `${accounts.length} accounts`}
              </div>
            </div>
          </div>
          {link ? (
            <CopyButton label="link" valueToCopy={link} />
          ) : (
            <Button
              size="large"
              onClick={loadLink}
              disabled={!!link}
              isLoading={loading}
              type="button"
            >
              Generate Link
            </Button>
          )}
        </div>
      </div>
      <div className="text-base font-normal leading-normal text-[#292a2d]">
        Send a login link to the customer to access their account.{' '}
      </div>
    </div>
  ) : null;
};

export const CreateTaxAccount = () => {
  const formMethods = useForm();

  return (
    <FakeNav>
      <PageHeader>
        <PageTitle>Request to Connect</PageTitle>
      </PageHeader>
      <FormProvider {...formMethods}>
        <form className="inline-flex w-full flex-col items-start justify-start gap-14 bg-white p-8">
          <div className="flex flex-col items-start justify-center gap-4 self-stretch overflow-hidden bg-white py-4">
            <div className="inline-flex items-center justify-between self-stretch border border-[#edf0f1] bg-[#f7f9f9] p-4">
              <div className="flex items-center justify-start gap-6">
                <div className="text-nowrap text-base font-bold leading-normal text-[#292a2d]">
                  Select Customer:
                </div>
                <SelectField
                  name="legal_entity"
                  options={LEGAL_ENTITIES}
                  className="!mb-0 w-[300px]"
                />
              </div>
              <Button
                variant="secondary"
                size="large"
                leftIcon={<PlusIcon className="size-4" />}
              >
                New Customer
              </Button>
            </div>
            <div className="inline-flex items-center justify-start self-stretch border border-[#edf0f1] bg-[#f7f9f9] p-4">
              <div className="flex h-11 items-center justify-start gap-6">
                <div className="text-nowrap text-base font-bold leading-normal text-[#292a2d]">
                  Select Region:
                </div>
                <SelectField
                  name="region"
                  options={REGIONS}
                  className="!mb-0 w-[300px]"
                />
              </div>
            </div>
            <AccountInput />
            <GenerateLink />
          </div>
        </form>
      </FormProvider>
    </FakeNav>
  );
};

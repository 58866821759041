import { components } from '@mosey/api-types';
import { ResolverType } from './types';

const NEW_TASKS_KEY = 'TASKS_FRAMEWORK_RESOLVER_NEW_TASKS';
const RESOLVER_FINISH_URL_KEY = 'resolverFinishUrl';

const makeStorageKey = (...items: unknown[]) => {
  return items
    .filter((value) => value !== null && value !== undefined && value !== '')
    .join('.');
};

export const getResolverStartDate = (
  resolverType: ResolverType,
  regionId?: string | null,
) => {
  const key = makeStorageKey(
    'resolver_completed_start_time',
    resolverType,
    regionId,
  );
  const statusStartDate = sessionStorage.getItem(key);

  if (!statusStartDate) {
    const newStatusStartDate = new Date()
      .toISOString()
      .replace('T', ' ')
      .replace('Z', '');

    sessionStorage.setItem(key, newStatusStartDate);

    return newStatusStartDate;
  }

  return statusStartDate;
};

export const setFinishRedirectUrl = (url: string) => {
  sessionStorage.setItem(RESOLVER_FINISH_URL_KEY, url);
};

export const clearFinishRedirectUrl = () => {
  sessionStorage.removeItem(RESOLVER_FINISH_URL_KEY);
};

export const getFinishRedirectUrl = () => {
  return sessionStorage.getItem(RESOLVER_FINISH_URL_KEY);
};

export const setNewTasksForSession = (
  parentTaskId: string,
  newTasks: components['schemas']['TaskRef'][],
  locationId?: string,
  resolverType?: ResolverType,
) => {
  const key = makeStorageKey(NEW_TASKS_KEY, locationId, resolverType);

  if (newTasks.length > 0) {
    const newTasksFromStorage = getNewTasksForSession();
    let parsedNewTasksFromStorage: Record<string, string[]> = {};

    if (newTasksFromStorage) {
      try {
        parsedNewTasksFromStorage = JSON.parse(newTasksFromStorage);
      } catch (error) {
        sessionStorage.removeItem(key);
      }
    }

    sessionStorage.setItem(
      key,
      JSON.stringify({
        ...parsedNewTasksFromStorage,
        [parentTaskId]: newTasks.map(({ id }) => id),
      }),
    );
  }
};

export const getNewTasksForSession = (
  locationId?: string,
  resolverType?: ResolverType,
) => {
  return sessionStorage.getItem(
    makeStorageKey(NEW_TASKS_KEY, locationId, resolverType),
  );
};

export const clearNewTasksForSession = (
  locationId?: string,
  resolverType?: ResolverType,
) => {
  sessionStorage.removeItem(
    makeStorageKey(NEW_TASKS_KEY, locationId, resolverType),
  );
};

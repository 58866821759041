import { Outlet } from 'react-router-dom';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';

export const Home = () => {
  return (
    <div className="flex h-full flex-col overflow-hidden">
      <PageHeader>
        <PageTitle>Home</PageTitle>
      </PageHeader>

      <Outlet />
    </div>
  );
};

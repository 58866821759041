import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../utils/hooks';
import { TasksOverviewInterstitial } from './TasksOverviewInterstitial';
import { isTaskDone } from '../utils';

interface TasksOverviewSetupGateProps {
  children: React.ReactNode;
}

export const TasksOverviewSetupGate = ({
  children,
}: TasksOverviewSetupGateProps) => {
  const locationId = useTasksOverviewLocationId();
  const { assessmentTasks = [] } = useTasksOverviewData();

  if (locationId && assessmentTasks.filter(isTaskDone).length === 0) {
    return <TasksOverviewInterstitial />;
  }

  return children;
};

import { useMemo } from 'react';
import {
  ActionFunction,
  LoaderFunction,
  useFetchers,
  useLoaderData,
  useParams,
} from 'react-router-dom';
import { components, paths } from '@mosey/api-types';
import { HomeIcon } from '@mosey/components/Icons';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';
import { AccountsTable } from '../../components/AccountsTable';
import { getLoginDataFromAgencies } from '../../utils/agency';
import { useUser } from '../../hooks/useUser';
import { api } from '../../utils/fetchApi';
import {
  AgencyAccountAttribute,
  AgencyAccountAttributeFormKey,
} from './AgencyAccountAttribute';

type AccountsResponse =
  paths['/api/legal_entity/locations/{region_code}/agencies']['get']['responses']['200']['content']['application/json'];

export const loader: LoaderFunction = async ({ params: { locationId } }) => {
  return api({
    url: `/api/legal_entity/locations/${locationId}/agencies?filter_unusable_entries=true`,
    method: 'GET',
  });
};

interface ActionResponse {
  errors: Record<string, string>;
  resolved: boolean;
}

type DatumUpdate =
  paths['/api/datums/{attribute_public_id}']['post']['requestBody']['content']['application/json'];

export const action: ActionFunction = async ({
  request,
}): Promise<ActionResponse> => {
  // eslint-disable-next-line camelcase
  const { public_id, value } = Object.fromEntries(await request.formData());

  try {
    await api({
      // eslint-disable-next-line camelcase
      url: `/api/datums/${public_id}`,
      method: 'POST',
      body: { value } as DatumUpdate,
    });
  } catch (error) {
    let submitError = 'Something went wrong, please try again.';

    if (error instanceof Response) {
      const parsedError = await error.json();
      submitError = parsedError.message;
    }

    return {
      errors: {
        submit: submitError,
      },
      resolved: false,
    };
  }

  return {
    errors: {},
    resolved: true,
  };
};

export const Component = () => {
  const fetchers = useFetchers();
  const importFetcher = fetchers.find(
    ({ key }) => key == AgencyAccountAttributeFormKey,
  );
  const agencies = useLoaderData() as AccountsResponse;
  const { locationId } = useParams();
  const {
    legal_entity: { incorporation_region: incorporationRegion },
  } = useUser();
  const isIncorporationState =
    incorporationRegion?.id.toLowerCase() === locationId?.toLowerCase();
  const logins = useMemo(() => getLoginDataFromAgencies(agencies), [agencies]);
  const agenciesWithDatums: {
    agency: components['schemas']['Agency'];
    attributes: components['schemas']['AgencyAccountAttribute'][];
  }[] = [];

  for (const agency of agencies) {
    const datums = agency.agency_accounts.flatMap(
      ({ attributes }) => attributes,
    );
    if (datums.length > 0) {
      agenciesWithDatums.push({ agency, attributes: datums });
    }
  }

  return (
    <div className="space-y-6 overflow-y-auto px-8 py-6">
      <section className="space-y-6">
        <h2 className="font-bold text-zinc-800">Registrations</h2>

        {isIncorporationState && (
          <div className="flex items-center gap-x-2 border p-6 text-sm">
            <span className="rounded-full bg-yellow-400 p-2 text-white">
              <HomeIcon className="size-4" />
            </span>
            <div>
              <div className="text-sm font-bold leading-none text-gray-700">
                Incorporation State
              </div>
              <TextLink
                to={`/locations/usa/${locationId}/certificate-of-good-standing`}
              >
                Request a Certificate of Good Standing...
              </TextLink>
            </div>
          </div>
        )}

        {agenciesWithDatums.length > 0 ? (
          <>
            <BlockAlert
              variant="error"
              message={importFetcher?.data?.errors?.submit}
              scrollIntoView
              show={
                !!importFetcher?.data?.errors?.submit &&
                importFetcher.state === 'idle'
              }
              aria-live="assertive"
            />

            <ul className="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3">
              {agenciesWithDatums.map(({ agency, attributes }) => {
                if (attributes.length === 0) {
                  return null;
                }

                return (
                  <li
                    key={agency.id}
                    className="space-y-3 rounded-lg border bg-white p-4"
                  >
                    <div className="text-xs uppercase tracking-widest text-zinc-600">
                      {agency.name}
                    </div>

                    {attributes.map((attr) => (
                      <AgencyAccountAttribute attribute={attr} key={attr.id} />
                    ))}
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <p className="border p-6 text-gray-400">
            Your account numbers and tax details will be listed here
          </p>
        )}
      </section>

      <section className="space-y-6">
        <h2 className="font-bold text-zinc-800">Login Credentials</h2>

        <div className="bg-white">
          <AccountsTable logins={logins} />
        </div>
      </section>
    </div>
  );
};

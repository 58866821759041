import { useFetchers, useSubmit } from 'react-router-dom';
import { Menu, MenuItems } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/solid';
import { UserIcon as UserIconUnassigned } from '@heroicons/react/outline';
import { MenuTransition } from '@mosey/components/menus/common/MenuTransition';
import { MenuItem } from '@mosey/components/menus/DropdownMenu';
import { MenuButton } from '@mosey/components/menus/MenuButton';
import { TaskStatusPill } from '@mosey/components/badges/TaskStatusPill';
import { useDueDate, useTask, useTaskUrl, useUsers } from '../utils/hooks';
import { nameFromUser } from '../../../utils/format';
import { getCalculatedTaskStatus } from '../utils';

export const TaskHeader = () => {
  const submit = useSubmit();
  const fetchers = useFetchers();
  const taskUrl = useTaskUrl();
  const users = useUsers();
  const task = useTask();
  const { title, assigned_user: assignedUser } = task;
  const dueDate = useDueDate();
  const pillStatus = getCalculatedTaskStatus(task);

  const assignToFetcher = fetchers.find(
    (f) => f.formAction === taskUrl && f.formMethod === 'patch',
  );
  const isAssignToLoading =
    assignToFetcher?.state === 'submitting' ||
    assignToFetcher?.state === 'loading';

  return (
    <header className="space-y-4">
      <div className="flex items-center gap-x-6">
        <TaskStatusPill status={pillStatus} />

        <div className="ml-auto flex items-center gap-x-6">
          {dueDate && (
            <div className="text-sm leading-6 text-zinc-700">
              Due <time dateTime={dueDate}>{dueDate}</time>
            </div>
          )}

          <Menu>
            <MenuButton
              aria-describedby="task-assign-to-menu-description"
              icon={assignedUser ? <UserIcon /> : <UserIconUnassigned />}
              isLoading={isAssignToLoading}
            >
              {assignedUser ? nameFromUser(assignedUser) : 'Assign to...'}
            </MenuButton>

            <span id="task-assign-to-menu-description" className="sr-only">
              This menu allows you to assign this task to a user.
            </span>

            <MenuTransition>
              <MenuItems
                anchor={{ to: 'bottom end', gap: 8, padding: 8 }}
                className="z-[1000] w-48 rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5"
              >
                <MenuItem
                  as="button"
                  onClick={() => {
                    submit(
                      { intent: 'assign-to' },
                      { method: 'PATCH', action: taskUrl, navigate: false },
                    );
                  }}
                  selected={!assignedUser}
                >
                  Unassigned
                </MenuItem>

                {users.map((user) => {
                  return (
                    <MenuItem
                      as="button"
                      onClick={() => {
                        submit(
                          // eslint-disable-next-line camelcase
                          { assigned_user_id: user.id, intent: 'assign-to' },
                          {
                            method: 'PATCH',
                            action: taskUrl,
                            navigate: false,
                          },
                        );
                      }}
                      key={user.id}
                      aria-pressed={assignedUser?.id === user.id}
                      selected={assignedUser?.id === user.id}
                    >
                      {nameFromUser(user)}
                    </MenuItem>
                  );
                })}
              </MenuItems>
            </MenuTransition>
          </Menu>
        </div>
      </div>

      <h2 className="grow text-4xl font-bold leading-10 tracking-tight">
        {title}
      </h2>
    </header>
  );
};

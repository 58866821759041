import { clsx } from 'clsx';
import { useSearchParams, Link } from 'react-router-dom';
import { BannerWithGraphic } from '@mosey/components/layout/BannerWithGraphic';
import inboxZeroImg from '../../../assets/tasks-framework/tasks-overview-inbox-zero.svg';
import automatedZeroImg from '../../../assets/tasks-framework/tasks-overview-automated-zero.svg';
import managedZeroImg from '../../../assets/tasks-framework/tasks-overview-managed-zero.svg';
import { useTasksOverviewData, useTasksOverviewLocation } from '../utils/hooks';
import { SetupGraphicCropped } from '../resolver/SetupGraphicCropped';
import { Button } from '@mosey/components/buttons/Button';
import { generateResolverTasksBrowserUrl } from '../utils';
import { TaskStatus } from '@mosey/api-types';
import { ResolverType } from '../../../views/tasks-framework/utils/types';

export const TasksOverviewZeroState = () => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');
  const { setupTasks = [] } = useTasksOverviewData();
  const todoSetupTasks = setupTasks.filter(
    (task) => task.status === TaskStatus.todo,
  );
  const location = useTasksOverviewLocation();

  let title = 'Inbox zero';
  let body: React.ReactNode = 'You have no outstanding tasks!';
  let imgSrc = inboxZeroImg;

  if (
    (!status || status === TaskStatus.todo) &&
    todoSetupTasks.length > 0 &&
    location !== null
  ) {
    const locationResolverUrl = generateResolverTasksBrowserUrl({
      locationId: location.code,
      resolverType: ResolverType.Setup,
    });

    return (
      <BannerWithGraphic
        title={`Finish setting up ${location.name}`}
        body={
          <>
            <p className="mb-4">
              You have {todoSetupTasks.length} setup tasks to complete.
            </p>
            <Button as={Link} to={locationResolverUrl} size="xlarge">
              Continue
            </Button>
          </>
        }
        graphic={
          <div className="my-4 mt-12 object-scale-down">
            {SetupGraphicCropped}
          </div>
        }
      />
    );
  }

  switch (status) {
    case 'overdue':
      title = 'You’re all caught up!';
      body = 'You have no overdue tasks.';
      break;

    case 'done':
      title = 'No completed tasks yet.';
      body = 'Tasks will show up here as you complete them.';
      break;

    case 'automated':
      title = 'No completed automations yet.';
      body = 'Automated tasks will show up here as they are completed.';
      imgSrc = automatedZeroImg;
      break;

    case 'managed':
      title = 'No managed tasks yet.';
      body = 'Tasks handled by integrations will show up here.';
      imgSrc = managedZeroImg;
      break;
  }

  return (
    <BannerWithGraphic
      title={title}
      body={body}
      variant={
        status === 'automated' || status === 'managed'
          ? 'automation'
          : 'default'
      }
      graphic={
        <img
          src={imgSrc}
          className={clsx(
            status === 'automated' && 'mr-20 mt-12',
            status === 'managed' && 'mt-12',
          )}
          alt=""
        />
      }
    />
  );
};

import { Button as AriaButton } from 'react-aria-components';
import { Button } from '@mosey/components/buttons/Button';
import { DownloadIcon, CheckCircleIcon } from '@mosey/components/Icons';
import { api } from '../../utils/fetchApi';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { Table } from '@mosey/components/table/Table';
import { HeaderRow } from '@mosey/components/table/HeaderRow';
import { BackButton } from '../../components';
import { SignaturesDonut } from './components/SignaturesDonut';
import {
  emailToFileName,
  getLabelFromAcknowledgement,
  getTaskCardStatusFromAcknowledgement,
} from './utils';
import { HeaderCell } from '@mosey/components/table/HeaderCell';
import { TableBody } from '@mosey/components/table/TableBody';
import { TableRow } from '@mosey/components/table/TableRow';
import { TableCell } from '@mosey/components/table/TableCell';
import { StatusCell } from '@mosey/components/table/StatusCell';
import { EmptyStateCell } from '@mosey/components/table/EmptyStateCell';
import { formatLocalDate } from '../../utils/format';
import { StandaloneStatusIcon } from '@mosey/components/layout/StatusIcon';
import { Tooltip } from '@mosey/components/layout/Tooltip';
import { QueryFilter } from '@mosey/components/menus/QueryFilter';
import {
  EmployeeAcknowledgementInfo,
  useSignatures,
} from './hooks/useSignatures';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { EmployeeAcknowledgementStatus } from '@mosey/api-types';
import { ActionFunctionArgs, useFetcher } from 'react-router-dom';
import { FunctionComponent, useState } from 'react';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';

const RemindedAt = ({
  employee,
}: {
  employee: EmployeeAcknowledgementInfo;
}) => {
  const { Form, state, data } = useFetcher();

  if (data?.success) {
    return (
      <TableCell>
        <div className="flex items-center gap-2 text-teal-700">
          <CheckCircleIcon className="size-5" />
          Reminder sent!
        </div>
      </TableCell>
    );
  } else if (employee.reminded_at) {
    return (
      <TableCell>
        <span className="text-zinc-700">
          {formatLocalDate(new Date(`${employee.reminded_at}Z`))}
        </span>
      </TableCell>
    );
  } else {
    return (
      <TableCell verticalPadding="none">
        <Form method="POST" encType="multipart/form-data">
          <Button
            variant="secondary"
            size="small"
            name="employeeId"
            value={employee.id}
            isLoading={state !== 'idle'}
          >
            Send reminder
          </Button>
        </Form>
      </TableCell>
    );
  }
};

const DownloadAcknowledgementCell = ({
  employee,
}: {
  employee: EmployeeAcknowledgementInfo;
}) => {
  const { status, id, email } = employee;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDownload = async (employeeId: string) => {
    setIsLoading(true);
    const response = await api({
      url: `/api/handbook/employees/${employeeId}/download_signature`,
      method: 'GET',
      credentials: 'include',
    });

    const blob = await response.blob();

    const filename = `${emailToFileName(email)}-signed-handbook.pdf`;

    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
    setIsLoading(false);
  };

  const shouldShowDownload =
    status === EmployeeAcknowledgementStatus.signed ||
    status === EmployeeAcknowledgementStatus.outdated;

  return (
    <TableCell verticalPadding="none">
      <div className="flex justify-end">
        {shouldShowDownload && (
          <Button
            size="small"
            variant="secondary"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={async () => await handleDownload(id)}
            rightIcon={<DownloadIcon className="ml-2 w-4" />}
          >
            Download
          </Button>
        )}
      </div>
    </TableCell>
  );
};

type DownloadAllButtonProps = {
  employees: EmployeeAcknowledgementInfo[];
  isCompleted: boolean;
  setIsCompleted: React.Dispatch<React.SetStateAction<boolean>>;
};

const DownloadAllButton: FunctionComponent<DownloadAllButtonProps> = ({
  employees,
  isCompleted,
  setIsCompleted,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const downloadAllSignatures = async (employeePublicIds: string[]) => {
    const response = await api({
      url: '/api/handbook/employees/download_signatures',
      method: 'POST',
      credentials: 'include',
      body: {
        handbook_user_public_ids: employeePublicIds,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to download signatures');
    }
  };

  const handleDownloadAll = async () => {
    setIsLoading(true);
    const eligibleEmployeeIds = employees
      .filter(
        (e: EmployeeAcknowledgementInfo) =>
          e.status === EmployeeAcknowledgementStatus.signed ||
          e.status === EmployeeAcknowledgementStatus.outdated,
      )
      .map((e) => e.id);
    await downloadAllSignatures(eligibleEmployeeIds);
    setIsLoading(false);
    setIsCompleted(true);
  };

  return (
    <Button
      size="small"
      variant={'secondary'}
      disabled={isLoading || isCompleted}
      isLoading={isLoading}
      onClick={handleDownloadAll}
      rightIcon={<DownloadIcon className="ml-2 w-4" />}
    >
      Download all
    </Button>
  );
};

export const Signatures = () => {
  const { allEmployees, filteredEmployees, filterOptions } = useSignatures();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const shouldShowDownloadAll =
    filteredEmployees.filter(
      (employee) =>
        employee.status === EmployeeAcknowledgementStatus.signed ||
        employee.status === EmployeeAcknowledgementStatus.outdated,
    ).length > 1;

  return (
    <>
      <PageHeader>
        <BackButton to="/handbook" />
      </PageHeader>
      <div className="px-8 py-4">
        <section>
          <h1 className="mb-6 text-xl font-semibold">Signatures</h1>
          <SignaturesDonut />
        </section>

        <section className="mt-8">
          <div className="mb-2 mt-3">
            <BlockAlert
              show={isCompleted}
              variant="info"
              message={
                'Your download is processing. You will receive an email with an attached ZIP file when it is ready.'
              }
            />
          </div>
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-xl font-semibold">Employees</h1>
            <div className="flex items-center gap-6">
              <QueryFilter queryKey="status" options={filterOptions.status} />
              <QueryFilter
                queryKey="location"
                options={filterOptions.location}
              />
              {shouldShowDownloadAll && (
                <DownloadAllButton
                  employees={filteredEmployees}
                  isCompleted={isCompleted}
                  setIsCompleted={setIsCompleted}
                />
              )}
            </div>
          </div>
          <Table>
            <HeaderRow>
              <th className="w-[42px]">
                <span className="sr-only">Status</span>
              </th>
              <HeaderCell>Email</HeaderCell>
              <HeaderCell>City</HeaderCell>
              <HeaderCell>State</HeaderCell>
              <HeaderCell>Last signed</HeaderCell>
              <HeaderCell>Last reminded</HeaderCell>
              <HeaderCell></HeaderCell>
            </HeaderRow>
            <TableBody>
              {filteredEmployees.length === 0 && (
                <EmptyStateCell colSpan={5}>
                  <div className="flex flex-col items-center gap-1">
                    <p>No employees found</p>
                    {allEmployees.length > 0 && (
                      <TextLink to="/handbook/signatures">
                        Clear filters
                      </TextLink>
                    )}
                  </div>
                </EmptyStateCell>
              )}
              {filteredEmployees.map((employee) => (
                <TableRow key={employee.id}>
                  <StatusCell>
                    <Tooltip
                      label={getLabelFromAcknowledgement(employee)}
                      offset={10}
                    >
                      <AriaButton className="size-[18px] pt-1">
                        <StandaloneStatusIcon
                          status={getTaskCardStatusFromAcknowledgement(
                            employee,
                          )}
                          colorOverride={
                            employee.status ===
                            EmployeeAcknowledgementStatus.outdated
                              ? 'text-amber-600'
                              : undefined
                          }
                        />
                      </AriaButton>
                    </Tooltip>
                  </StatusCell>
                  <TableCell>
                    <span className="text-zinc-700">{employee.email}</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-zinc-700">{employee.city_name}</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-zinc-700">
                      {employee.region.name}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="text-zinc-700">
                      {employee.acknowledged_at
                        ? formatLocalDate(
                            new Date(`${employee.acknowledged_at}Z`),
                          )
                        : '--'}
                    </span>
                  </TableCell>
                  <RemindedAt employee={employee} />
                  <DownloadAcknowledgementCell employee={employee} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </section>
      </div>
    </>
  );
};

Signatures.loader = async () => {
  return api({
    method: 'GET',
    url: '/api/handbook/employees',
  });
};

Signatures.action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  await api({
    method: 'POST',
    url: `/api/handbook/employees/${formData.get('employeeId')}/reminder`,
    body: {},
  });
  return { success: true };
};

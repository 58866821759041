import {
  components,
  paths,
  TagInstance,
  TaskDateFilter,
  TaskStatus,
  TaskType,
} from '@mosey/api-types';
import {
  convertDateStringToLocalDate,
  getLocalTodayMidnight,
  toUTCMidnight,
} from '@mosey/utils/dates';
import { isUSStateCode } from '@mosey/utils/constants/us-states';
import { ResolverType } from './types';
import { formatDateFromString, isoDate } from '../../../utils/format';
import { IFetchApi } from '../../../utils/types';
import { generatePath, redirect } from 'react-router-dom';
import { getResolverStartDate } from './session';
import { TaskCardStatus } from '@mosey/components/layout/types';

type Task = components['schemas']['Task'];
type TaskRef = components['schemas']['TaskRef'];

export const isTaskSetupRelated = ({
  source: { is_setup_related: isSetupRelated },
}: TaskRef) => {
  return isSetupRelated;
};

export const isTaskTodo = ({ status }: Task | TaskRef) => {
  return status === TaskStatus.todo;
};

export const isTaskDone = ({ status }: Task | TaskRef) => {
  return status === TaskStatus.done;
};

export const isTaskOverdue = ({ status, source }: Task | TaskRef) => {
  if (source.type === TaskType.requirement && 'due_date' in source) {
    const { due_date: dueDate, managed_provider: managedProvider } = source;

    if (managedProvider) {
      return false;
    }

    if (dueDate && status === TaskStatus.todo) {
      const localToday = getLocalTodayMidnight();
      const localDueDate = convertDateStringToLocalDate(dueDate);

      return localDueDate < localToday;
    }
  }

  return false;
};

/**
 * Automated here is past tense, so the task must have status of done. This is
 * distinct from managed or in progress tasks.
 */
export const isTaskAutomated = (task: TaskRef | Task) => {
  return (
    task.source.type === 'requirement' &&
    task.status === 'done' &&
    task.source.managed_provider?.name === 'Mosey'
  );
};

export const isTaskManaged = (task: TaskRef | Task) => {
  return (
    task.source.type === 'requirement' &&
    task.source.managed_provider &&
    task.source.managed_provider.name !== 'Mosey'
  );
};

export const isTaskInProgress = (task: TaskRef) => {
  return (
    task.source.type === 'requirement' &&
    task.status === 'in-progress' &&
    task.source.managed_provider?.name === 'Mosey'
  );
};

export const getNextTask = (
  resolverType: string | undefined,
  tasks: TaskRef[],
  taskId?: Task['id'],
) => {
  if (taskId) {
    const currentIndex = tasks.findIndex(({ id }) => id === taskId) + 1;

    if (resolverType === ResolverType.Review) {
      return tasks.at(currentIndex);
    }

    return tasks
      .slice(currentIndex)
      .concat(tasks.slice(0, currentIndex))
      .find(isTaskTodo);
  }

  return tasks.find(isTaskTodo);
};

export const getTaskDueDate = ({ source }: Task | TaskRef) => {
  return source.type === TaskType.requirement &&
    'due_date' in source &&
    source.due_date
    ? formatDateFromString(source.due_date, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    : null;
};

export const formatQuestionAnswer = (
  answer: components['schemas']['TaskQuestion']['answer'],
) => {
  if (answer === 'true') {
    return 'Yes';
  } else if (answer === 'false') {
    return 'No';
  }

  return answer;
};

export const isResolverType = (value: string): value is ResolverType => {
  return Object.values(ResolverType).includes(value as ResolverType);
};

type TasksRequestQueryParameters =
  paths['/api/compliance/tasks']['get']['parameters']['query'];

export const generateResolverTasksAPIUrl = (
  resolverType?: string | null,
  locationId?: string | null,
  isResolver?: boolean,
): IFetchApi => {
  let url = '/api/compliance/tasks';
  const queryParameters: TasksRequestQueryParameters = {};

  if (locationId) {
    if (isUSStateCode(locationId.toUpperCase())) {
      // eslint-disable-next-line camelcase
      queryParameters.region_id = locationId;
    } else {
      throw redirect('/home');
    }
  }

  let endDate: Date | string = toUTCMidnight(new Date());

  endDate.setDate(endDate.getDate() + 90);
  endDate = isoDate(endDate);

  if (resolverType) {
    if (!isResolverType(resolverType)) {
      throw redirect('/home');
    }

    /* eslint-disable camelcase */
    switch (resolverType) {
      case ResolverType.Review:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.statuses = [TaskStatus.done, TaskStatus.todo];
        queryParameters.task_type = TaskType.question;
        queryParameters.is_managed = false;

        break;

      case ResolverType.Assessment:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.task_type = TaskType.question;
        queryParameters.is_managed = false;

        break;

      case ResolverType.Setup:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.is_setup = true;
        queryParameters.task_type = TaskType.requirement;
        queryParameters.is_managed = false;

        break;

      case ResolverType.Question:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.is_setup = false;
        queryParameters.completed_start_time = getResolverStartDate(
          resolverType,
          locationId,
        );
        queryParameters.task_type = TaskType.question;
        queryParameters.is_managed = false;

        break;

      case ResolverType.Todo:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.is_setup = false;
        queryParameters.end_date = endDate;
        queryParameters.completed_start_time = getResolverStartDate(
          resolverType,
          locationId,
        );
        queryParameters.task_type = TaskType.requirement;
        queryParameters.statuses = [TaskStatus.todo];

        if (isResolver) {
          queryParameters.statuses.push(TaskStatus.done);
        }

        queryParameters.is_managed = false;

        break;

      case ResolverType.Overdue:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.completed_start_time = getResolverStartDate(
          resolverType,
          locationId,
        );
        queryParameters.date_filter = TaskDateFilter.overdue;
        queryParameters.task_type = TaskType.requirement;
        queryParameters.statuses = [TaskStatus.todo];

        if (isResolver) {
          queryParameters.statuses.push(TaskStatus.done);
        }

        queryParameters.is_managed = false;

        break;

      case ResolverType.Done:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.is_setup = false;
        queryParameters.task_type = TaskType.requirement;
        queryParameters.statuses = [TaskStatus.done];
        queryParameters.is_automation = false;
        queryParameters.is_managed = false;

        break;

      case ResolverType.Automated:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.task_type = TaskType.requirement;
        queryParameters.statuses = [TaskStatus.done];
        queryParameters.is_automation = true;
        queryParameters.is_managed = false;

        break;

      case ResolverType.Managed:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.end_date = endDate;
        queryParameters.task_type = TaskType.requirement;
        queryParameters.is_managed = true;

        break;

      case ResolverType.InProgress:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.task_type = TaskType.requirement;
        queryParameters.statuses = [TaskStatus.in_progress];
        queryParameters.is_automation = true;
        queryParameters.is_managed = false;

        break;

      case ResolverType.Handbook:
        queryParameters.task_type = TaskType.requirement;
        queryParameters.tags = [TagInstance.handbook];
        queryParameters.statuses = [TaskStatus.todo];
        queryParameters.completed_start_time = getResolverStartDate(
          resolverType,
          locationId,
        );

        if (isResolver) {
          queryParameters.statuses.push(TaskStatus.done);
        }

        queryParameters.is_managed = false;
    }
    /* eslint-enable camelcase */
  }

  const searchParams = new URLSearchParams();
  Object.entries(queryParameters).forEach(([key, value]) => {
    if (value === null) {
      searchParams.append(key, 'null');
    } else if (Array.isArray(value)) {
      for (let j = 0; j < value.length; j++) {
        searchParams.append(key, value[j]);
      }
    } else {
      searchParams.append(key, value.toString());
    }
  });

  if (searchParams.size > 0) {
    url += `?${searchParams.toString()}`;
  }

  return { url, method: 'GET' };
};

export const generateResolverTasksBrowserUrl = ({
  resolverType,
  locationId,
  taskId,
  searchParams,
}: {
  resolverType?: string | null;
  locationId?: string | null;
  taskId?: string | null;
  searchParams?: ConstructorParameters<typeof URLSearchParams>[0];
} = {}) => {
  let path = '';

  if (locationId) {
    if (isUSStateCode(locationId.toUpperCase())) {
      path += '/locations/:countryId/:locationId';
      locationId = locationId.toLowerCase();
    } else {
      throw new Error(`Invalid locationId: ${locationId}`);
    }
  } else {
    path += '/home';
  }

  if (resolverType && !isResolverType(resolverType)) {
    throw new Error(`Invalid resolverType: ${resolverType}`);
  }

  path += '/resolver/:resolverType?';

  if (taskId) {
    path += '/tasks/:taskId';
  }

  let renderedPath = generatePath(path, {
    countryId: 'usa',
    locationId,
    resolverType,
    taskId,
  });

  searchParams = new URLSearchParams(searchParams);

  if (searchParams && searchParams.size > 0) {
    renderedPath += `?${searchParams.toString()}`;
  }

  return renderedPath;
};

type CalculatedTaskStatus = Exclude<
  TaskCardStatus,
  'setup-collection' | 'question-collection'
>;

export const getCalculatedTaskStatus = (
  task: TaskRef | Task,
): CalculatedTaskStatus => {
  const { status } = task;
  let calculatedStatus: CalculatedTaskStatus =
    status === 'deferred' ? 'todo' : status;

  if (isTaskManaged(task)) {
    calculatedStatus = 'managed';
  } else if (isTaskOverdue(task)) {
    calculatedStatus = 'overdue';
  } else if (isTaskAutomated(task)) {
    calculatedStatus = 'automated';
  }

  return calculatedStatus;
};

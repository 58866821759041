import { TruncatedList } from '@mosey/components/layout/TruncatedList';
import { CardWrapper } from '@mosey/components/layout/CardWrapper';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { useLegalEntityRegions } from '../../../../hooks/useUser';
import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../../utils/hooks';
import { ResolverType } from '../../utils/types';
import { generateResolverTasksBrowserUrl } from '../../utils';

export const AssessmentCollection = () => {
  const locationId = useTasksOverviewLocationId();
  const regions = useLegalEntityRegions();
  const { summary, assessmentTasks = [] } = useTasksOverviewData();
  const assessmentAlerts = locationId
    ? []
    : regions.filter((region) => summary[region.code].is_assessment);

  return (
    <TruncatedList
      title="Answer Questions"
      body="Your responses generate setup tasks."
      itemLabel={locationId ? 'tasks' : 'locations'}
      as="nav"
      aria-live="polite"
      isStacked={locationId !== null}
    >
      {locationId
        ? assessmentTasks.map(({ id, title, status }) => {
            return (
              <CardWrapper key={id} as="li">
                <TaskSummaryCard
                  title={title}
                  status={status === 'deferred' ? 'todo' : status}
                  variant="nav"
                  size="medium"
                  to={generateResolverTasksBrowserUrl({
                    locationId,
                    resolverType: ResolverType.Assessment,
                    taskId: id,
                  })}
                />
              </CardWrapper>
            );
          })
        : assessmentAlerts.map((region) => {
            return (
              <CardWrapper key={region.code} as="li">
                <TaskSummaryCard
                  title={region.name}
                  status="question-collection"
                  variant="collection"
                  size="medium"
                  to={generateResolverTasksBrowserUrl({
                    locationId: region.code,
                    resolverType: ResolverType.Assessment,
                  })}
                  aria-label={`${region.name} assessment`}
                />
              </CardWrapper>
            );
          })}
    </TruncatedList>
  );
};

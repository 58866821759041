import { TruncatedList } from '@mosey/components/layout/TruncatedList';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../../utils/hooks';
import { generateResolverTasksBrowserUrl } from '../../utils';
import { useLegalEntityRegions } from '../../../../hooks/useUser';
import { CardWrapper } from '@mosey/components/layout/CardWrapper';
import { Navigate } from 'react-router-dom';
import { ResolverType } from '../../utils/types';

export const SetupCollection = () => {
  const locationId = useTasksOverviewLocationId();
  const regions = useLegalEntityRegions();
  const { setupTasks = [], summary } = useTasksOverviewData();
  const setupAlerts = locationId
    ? []
    : regions.filter((region) => summary[region.code].is_setup);

  if (locationId) {
    const currentRegion = regions.find(
      (region) => region.code.toLowerCase() === locationId.toLowerCase(),
    );

    if (!currentRegion) {
      return <Navigate to="/home" replace />;
    }

    const regionName = currentRegion.name;
    const payrollTasks = setupTasks.filter(
      (task) =>
        'navigator_section' in task.source &&
        task.source.navigator_section === 'payroll',
    );
    const hrTasks = setupTasks.filter(
      (task) =>
        'navigator_section' in task.source &&
        task.source.navigator_section === 'hr',
    );
    const registrationTasks = setupTasks.filter(
      (task) =>
        'navigator_section' in task.source &&
        task.source.navigator_section === 'registration',
    );
    const corporateTaxTasks = setupTasks.filter(
      (task) =>
        'navigator_section' in task.source &&
        task.source.navigator_section === 'corporate-tax',
    );
    const salesTaxTasks = setupTasks.filter(
      (task) =>
        'navigator_section' in task.source &&
        task.source.navigator_section === 'tax',
    );

    return (
      <>
        <TruncatedList
          title="Payroll Setup"
          body={
            payrollTasks.length > 0
              ? undefined
              : `You have no employees in ${regionName}`
          }
          itemLabel="tasks"
          as="nav"
          aria-live="polite"
          isStacked
        >
          {payrollTasks.map(({ id, title, status }) => {
            return (
              <CardWrapper as="li" key={id}>
                <TaskSummaryCard
                  title={title}
                  status={status === 'deferred' ? 'todo' : status}
                  variant="nav"
                  size="medium"
                  to={generateResolverTasksBrowserUrl({
                    locationId,
                    resolverType: ResolverType.Setup,
                    taskId: id,
                  })}
                />
              </CardWrapper>
            );
          })}
        </TruncatedList>
        <TruncatedList
          title="HR Setup"
          body={
            hrTasks.length > 0
              ? undefined
              : `You have no employees in ${regionName}`
          }
          itemLabel="tasks"
          as="nav"
          aria-live="polite"
          isStacked
        >
          {hrTasks.map(({ id, title, status }) => {
            return (
              <CardWrapper as="li" key={id}>
                <TaskSummaryCard
                  title={title}
                  status={status === 'deferred' ? 'todo' : status}
                  variant="nav"
                  size="medium"
                  to={generateResolverTasksBrowserUrl({
                    locationId,
                    resolverType: ResolverType.Setup,
                    taskId: id,
                  })}
                />
              </CardWrapper>
            );
          })}
        </TruncatedList>
        <TruncatedList
          title="Registration"
          body={
            registrationTasks.length > 0
              ? undefined
              : `You do not have Secretary of State nexus in ${regionName}`
          }
          itemLabel="tasks"
          as="nav"
          aria-live="polite"
          isStacked
        >
          {registrationTasks.map(({ id, title, status }) => {
            return (
              <CardWrapper as="li" key={id}>
                <TaskSummaryCard
                  title={title}
                  status={status === 'deferred' ? 'todo' : status}
                  variant="nav"
                  size="medium"
                  to={generateResolverTasksBrowserUrl({
                    locationId,
                    resolverType: ResolverType.Setup,
                    taskId: id,
                  })}
                />
              </CardWrapper>
            );
          })}
        </TruncatedList>
        <TruncatedList
          title="Corporate Franchise Tax Setup"
          body={
            corporateTaxTasks.length > 0
              ? undefined
              : `You do not have corporate tax nexus in ${regionName}`
          }
          itemLabel="tasks"
          as="nav"
          aria-live="polite"
          isStacked
        >
          {corporateTaxTasks.map(({ id, title, status }) => {
            return (
              <CardWrapper as="li" key={id}>
                <TaskSummaryCard
                  title={title}
                  status={status === 'deferred' ? 'todo' : status}
                  variant="nav"
                  size="medium"
                  to={generateResolverTasksBrowserUrl({
                    locationId,
                    resolverType: ResolverType.Setup,
                    taskId: id,
                  })}
                />
              </CardWrapper>
            );
          })}
        </TruncatedList>
        <TruncatedList
          title="Sales Tax Setup"
          body={
            salesTaxTasks.length > 0
              ? undefined
              : `You do not have sales tax nexus in ${regionName}`
          }
          itemLabel="tasks"
          as="nav"
          aria-live="polite"
          isStacked
        >
          {salesTaxTasks.map(({ id, title, status }) => {
            return (
              <CardWrapper as="li" key={id}>
                <TaskSummaryCard
                  title={title}
                  status={status === 'deferred' ? 'todo' : status}
                  variant="nav"
                  size="medium"
                  to={generateResolverTasksBrowserUrl({
                    locationId,
                    resolverType: ResolverType.Setup,
                    taskId: id,
                  })}
                />
              </CardWrapper>
            );
          })}
        </TruncatedList>
      </>
    );
  }

  return (
    <TruncatedList
      title="Complete Setup"
      body="Setup tasks are based on your responses to questions."
      itemLabel={locationId ? 'tasks' : 'locations'}
      as="nav"
      aria-live="polite"
      isStacked={locationId !== null}
    >
      {setupAlerts.map((region) => (
        <CardWrapper key={region.code}>
          <TaskSummaryCard
            title={region.name}
            status="setup-collection"
            variant="collection"
            size="medium"
            to={generateResolverTasksBrowserUrl({
              locationId: region.code,
              resolverType: ResolverType.Setup,
            })}
            aria-label={`${region.name} setup`}
          />
        </CardWrapper>
      ))}
    </TruncatedList>
  );
};

import { AccountsTable } from '../../../components';

const EDD_LOGIN = {
  username: 'elsewhere_cafe',
  password: 'this_isnt_the_real_password',
  security_questions: [],
  account_name: 'CA Employer Services Online',
  login_url: 'https://eddservices.edd.ca.gov/tap/secure/eservices/',
  is_active: true,
  is_supported: true,
  is_otp_required: false,
  public_id: 'e761fd92486f2200',
  is_email_otp: false,
  working_as_of: '2024-08-27T17:36:23.495934',
  agencies: [
    {
      id: 'ef7beb3c02f12c58',
      name: 'California Employment Development Department',
      url: 'https://edd.ca.gov/en/employers',
      agency_accounts: [
        {
          id: 21,
          name: 'California Employer Payroll Tax Account (EDD)',
          attributes: [
            {
              id: 115,
              public_id: 'ca-edd-number',
              name: 'Employment Development Department (EDD) Number',
              description: null,
              required: false,
              value: '438-4718-7',
            },
          ],
          logins: [
            {
              username: 'elsewhere_cafe',
              password: 'this_isnt_the_real_password',
              security_questions: [],
              account_name: 'CA Employer Services Online',
              login_url: 'https://eddservices.edd.ca.gov/tap/secure/eservices/',
              is_active: true,
              is_supported: true,
              is_otp_required: false,
              public_id: 'e761fd92486f2200',
              is_email_otp: false,
              working_as_of: '2024-08-27T17:36:23.495934',
            },
          ],
        },
      ],
    },
  ],
};

export const TaxAccountLogins = () => {
  return (
    <div className="space-y-8 p-8">
      <AccountsTable logins={[EDD_LOGIN]} />
    </div>
  );
};

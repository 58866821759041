import { Button } from '@mosey/components/buttons/Button';
import { ComponentType, PropsWithChildren, ReactNode, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { getStateSealImage } from '../../../utils/seals';
import { USStateAbbrev } from '@mosey/utils/constants/us-states';
import {
  ChartSquareBarIcon,
  KeyIcon,
  SwitchHorizontalIcon,
} from '@heroicons/react/outline';
import { BaseTextField } from '@mosey/components/forms/BaseFormField';
import { LoadingIcon, LockIcon } from '@mosey/components/Icons';
import checkedTaskImg from '../../../assets/checked-task.png';
import { markAccountConnectionStarted } from './utils';
import logo from '../../../assets/logo.svg';

type LoaderData = {
  partner: {
    name: string;
    url: string;
  };
  login: {
    name: string;
    url: string;
    region: {
      name: string;
      code: USStateAbbrev;
    };
  };
};

const Heading = ({ children }: PropsWithChildren) => {
  return (
    <h1 className="text-2xl font-bold tracking-tight text-[#203435]">
      {children}
    </h1>
  );
};

const Reference = ({
  subtitle,
  title,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <div>
      <p className="font-semibold">{title}</p>
      <p className="font-[450] text-[#717171]">{subtitle}</p>
    </div>
  );
};

type PanelProps = {
  children: ReactNode;
  showFooter?: boolean;
};
const Panel = ({ children, showFooter = false }: PanelProps) => {
  return (
    <div className="w-full max-w-sm">
      <section className="flex min-h-[460px] w-full flex-col rounded-[6px] border border-[#CDCDC6] bg-[rgba(228,228,220,0.59)]">
        <div className="flex w-full flex-1 flex-col gap-8 p-8">{children}</div>
        {showFooter && (
          <div className="inline-flex items-center justify-center gap-2 bg-[#e4e4dc] py-2">
            <LockIcon className="size-[14px] text-[#516b66]" />
            <div className="text-[13px] leading-[16.90px] text-[#203435] opacity-60">
              Transfer of your account information is encrypted.
            </div>
          </div>
        )}
      </section>
      <div className="mt-8 flex items-center justify-center gap-1">
        <div className="whitespace-nowrap text-sm text-gray-500">
          Powered by
        </div>
        <a href="https://mosey.com" target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="Logo" className="mt-1 h-4" />
        </a>
      </div>
    </div>
  );
};

type WelcomeBulletProps = {
  children: ReactNode;
  Icon: ComponentType<{
    className?: string;
  }>;
};
const WelcomeBullet = ({ children, Icon }: WelcomeBulletProps) => {
  return (
    <div className="flex min-h-[25px] items-center gap-3">
      <Icon className="size-6 min-w-6 text-[#516B66]" />
      <p className="text-sm font-medium leading-[18.20px] text-[#2e2f2f] opacity-90">
        {children}
      </p>
    </div>
  );
};

const Welcome = ({ onContinue }: { onContinue: VoidFunction }) => {
  const { login, partner } = useLoaderData() as LoaderData;
  return (
    <Panel showFooter>
      <div className="flex items-center gap-[14px]">
        <img
          src={getStateSealImage(login.region.code)}
          className="size-12"
          alt="State seal"
        />
        <SwitchHorizontalIcon className="size-6 text-[#285D60]" />{' '}
        <Reference title={partner.name} subtitle={partner.url} />
      </div>
      <Heading>
        Connect your {login.name} account to {partner.name}
      </Heading>
      <div className="flex flex-col gap-3">
        <div className="text-[13px] leading-[16.90px] text-[#2e2f2f] opacity-60">
          This application will be able to:
        </div>
        <WelcomeBullet Icon={KeyIcon}>Save your login info</WelcomeBullet>
        <WelcomeBullet Icon={ChartSquareBarIcon}>
          View your balances
        </WelcomeBullet>
      </div>
      <div className="flex flex-1 flex-col justify-end space-y-3">
        <Button
          isFullWidth
          size="large"
          variant="marketingPrimary"
          onClick={onContinue}
        >
          Continue
        </Button>
        <p className="text-xs text-[#717171]">
          By continuing, you agree to the{' '}
          <a
            href="https://mosey.com/privacy_policy"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Mosey Privacy Policy
          </a>
          .
        </p>
      </div>
    </Panel>
  );
};

const Credentials = ({ onContinue }: { onContinue: VoidFunction }) => {
  const { login } = useLoaderData() as LoaderData;
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    await fakeLoader(2000);
    onContinue();
  };

  return (
    <Panel>
      {loading ? (
        <div className="flex flex-1 flex-col items-center justify-center gap-6">
          <LoadingIcon className="size-24 self-center text-teal-900 opacity-50" />
          <p className="self-center font-medium text-[#717171]">Connecting…</p>
        </div>
      ) : (
        <>
          <div className="flex items-center gap-[14px]">
            <img
              src={getStateSealImage(login.region.code)}
              className="size-12"
              alt="State seal"
            />
            <Reference title={login.name} subtitle={login.url} />
          </div>
          <div>
            <Heading>Enter your credentials</Heading>
            <p className="font-medium text-[#717171]">
              or{' '}
              <a
                href="https://eddservices.edd.ca.gov/acctservices/AccountManagement/AccountServlet?Command=NEW_SIGN_UP"
                className="text-teal-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                create an account
              </a>
            </p>
          </div>
          <div className="flex flex-col gap-[26px]">
            <BaseTextField name="username" placeholder="Username" />
            <BaseTextField
              name="password"
              placeholder="Pasword"
              type="password"
            />
          </div>
          <div className="flex flex-1 flex-col justify-end space-y-3">
            <Button
              isFullWidth
              size="large"
              variant="marketingPrimary"
              onClick={save}
            >
              Continue
            </Button>
            <p className="text-xs text-[#717171]">
              Having trouble?{' '}
              <a
                href="https://mosey.com/privacy_policy"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Get help logging in.
              </a>
            </p>
          </div>
        </>
      )}
    </Panel>
  );
};

const Connected = ({ onContinue }: { onContinue: VoidFunction }) => {
  return (
    <Panel>
      <img src={checkedTaskImg} className="h-[28px] self-start" alt="" />
      <div className="flex flex-col gap-2">
        <Heading>Account connected!</Heading>
        <p className="self-center text-sm font-[450] text-[#2E2F2F] opacity-90">
          Gusto now has access to your California e‑Services account.
        </p>
      </div>
      <div className="flex flex-1 flex-col justify-end py-[30px]">
        <Button
          isFullWidth
          size="large"
          variant="marketingPrimary"
          onClick={onContinue}
        >
          Continue
        </Button>
      </div>
    </Panel>
  );
};

export const CredentialCollection = () => {
  const [step, setStep] = useState(0);
  return (
    <main className="relative flex min-h-screen min-w-full flex-col items-center justify-center bg-[#FCFBFA]">
      {step === 0 && <Welcome onContinue={() => setStep(1)} />}
      {step === 1 && (
        <Credentials
          onContinue={() => {
            setStep(2);
            markAccountConnectionStarted();
          }}
        />
      )}
      {step === 2 && <Connected onContinue={() => setStep(0)} />}
    </main>
  );
};

CredentialCollection.loader = () => {
  return {
    partner: {
      name: 'Gusto',
      url: 'gusto.com',
    },
    login: {
      name: 'California e‑Services',
      url: 'edd.ca.gov',
      region: {
        name: 'California',
        code: 'CA',
      },
    },
  };
};

export const fakeLoader = (time: number = 1500) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('success');
    }, time);
  });
};

import { useEffect } from 'react';
import { LoaderFunction, Outlet, useLocation } from 'react-router-dom';
import { api } from '../../../utils/fetchApi';
import { clearFinishRedirectUrl, setFinishRedirectUrl } from '../utils/session';
import { generateResolverTasksAPIUrl } from '../utils';

export const loader: LoaderFunction = async ({
  params: { locationId, resolverType },
}) => {
  return api(generateResolverTasksAPIUrl(resolverType, locationId, true));
};

export const Component = () => {
  const { state } = useLocation();

  useEffect(() => {
    /**
     * When coming to a resolver from JM, store the URL back to JM so the back
     * finish buttons in the resolver know where to take the user.
     */
    if (state?.resolverFinishUrl) {
      setFinishRedirectUrl(state.resolverFinishUrl);
    }

    return () => {
      // If leaving the resolver, clear the URL from storage
      clearFinishRedirectUrl();
    };
    /**
     * We only want this to run once when the component mounts, that is, when
     * you arrive from another route, and only want the cleanup function to run
     * when leaving the resolver, hence the disabled linting rule.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet />;
};

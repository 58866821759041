import { useState } from 'react';
import { Link } from 'react-router-dom';
import { XIcon } from '@heroicons/react/outline';
import { Button } from '@mosey/components/buttons/Button';
import { TruncatedList } from '@mosey/components/layout/TruncatedList';
import { CardWrapper } from '@mosey/components/layout/CardWrapper';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../../utils/hooks';
import {
  useIsHandbookEnabled,
  useIsHandbookPresent,
} from '../../../handbook/hooks/useIsHandbook';
import handbookIcon from '../../../../assets/handbook.svg';
import { generateResolverTasksBrowserUrl } from '../../utils';
import { ResolverType } from '../../utils/types';

export const HandbookTodoCollection = () => {
  const locationId = useTasksOverviewLocationId();
  const { handbookTodos } = useTasksOverviewData();
  const isHandbookEnabled = useIsHandbookEnabled();
  const isHandbookPresent = useIsHandbookPresent();
  const [showHandbookCta, setShowHandbookCta] = useState<boolean>(
    handbookTodos.length > 0 &&
      (!isHandbookEnabled || !isHandbookPresent) &&
      localStorage.getItem('isHandbookCtaDismissed') !== 'true',
  );
  const handbookCta = showHandbookCta && (
    <div className="relative flex gap-x-4 rounded-md border border-sage-500 bg-sage-100 p-4">
      <button
        className="absolute right-3 top-3 border-none p-0"
        onClick={() => {
          localStorage.setItem('isHandbookCtaDismissed', 'true');
          setShowHandbookCta(false);
        }}
      >
        <XIcon className="size-4 text-sage-700" aria-label="Dismiss" />
      </button>

      <img src={handbookIcon} alt="" className="w-32" />

      <div>
        <p className="text-sm font-semibold text-zinc-700">
          Launch your multi-state employee handbook in minutes.
        </p>
        <p className="mb-2 mt-1 text-xs text-zinc-600">
          Everything you need to make a compliant handbook for all 50 states.
        </p>

        <Button as={Link} to="/handbook" size="small" variant="secondary">
          Get started
        </Button>
      </div>
    </div>
  );

  return (
    <TruncatedList
      title="Adopt Policies"
      body="Add state-specific policies to your employee handbook."
      extra={handbookCta}
      itemLabel="policies"
      as="nav"
      aria-live="polite"
      isStacked
    >
      {handbookTodos.map(({ id, title, status }) => {
        return (
          <CardWrapper key={id} as="li">
            <TaskSummaryCard
              title={title}
              status={status === 'deferred' ? 'todo' : status}
              variant="nav"
              size="medium"
              to={generateResolverTasksBrowserUrl({
                locationId,
                resolverType: ResolverType.Handbook,
                taskId: id,
              })}
            />
          </CardWrapper>
        );
      })}
    </TruncatedList>
  );
};

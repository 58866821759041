import { forwardRef } from 'react';

interface TaskContainerProps {
  children: React.ReactNode;
}

export const TaskContainer = forwardRef<HTMLDivElement, TaskContainerProps>(
  function TaskContainer({ children }, ref) {
    return (
      <article
        ref={ref}
        className="flex h-full grow flex-col gap-y-4 overflow-y-auto p-8"
      >
        {children}
      </article>
    );
  },
);
